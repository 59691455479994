<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          variant="success"
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <div class="card">
          <b-overlay
            :show="overlayFlag"
            :spinner-variant="this.$overlayVariant"
            :spinner-type="this.$overlayType"
            :rounded="this.$overlayRounded"
          >
            <div class="card-body">
              <p class="card-title-desc">รายละเอียดเอกสาร</p>
              <!-- skeleton  -->
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="branchId">สาขา:</label>

                        <multiselect
                          disabled
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          class=""
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="handleSearch"
                        ></multiselect>
                      </div>
                    </div> -->
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขที่เอกสาร:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.regJobCode"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="reqDate"> วันที่:</label>

                        <date-picker
                          disabled
                          v-model="form.sendDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-12 col-sm-12 col-12">
                          <div class="mb-3 position-relative">
                            <label for="regCode">
                              เลือกจากเลขที่ใบแจ้งจำหน่าย:</label
                            >
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.regCode"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12">
                          <div class="mb-3 position-relative">
                            <label for="customerNameTh">ชื่อลูกค้า:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.customerFullNameTh"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="vehicleId">ทะเบียนรถ:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.vehicleId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขตัวถัง:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.vin"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-6 col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="modelNameTh">รุ่น:</label>

                        <b-form-input
                          disabled
                          id="modelNameTh"
                          v-model="form.modelNameTh"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="paymentId">เลขที่ใบเสร็จรับเงิน:</label>
                            <b-form-input
                              disabled
                              id="vin"
                              v-model="form.paymentId"
                              type="text"
                              class="form-control"
                              value="Otto"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-4">
                      <div class="mb-2">
                        <label class="form-label d-block mb-3"></label>
                        <b-form-radio-group
                          disabled
                          v-model="form.isSale"
                          :options="optionSale"
                          name="radios-stacked"
                          stacked
                        >
                        </b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          disabled
                          v-model="form.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <br />
                  <!-- <div class="col-md">
                    <button
                      :disabled="this.form.status !== 'BRCUS'"
                      class="btn btn-success float-end"
                      type="submit"
                    >
                      บันทึก
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </b-overlay>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>

              <document-component
                :dataImg="apiImg"
                :dataCheck="apiCheck"
                :dataShow="apiShow"
                :receivedBy="receivedBy"
                :isReceived="isReceived"
              >
              </document-component>
            </b-skeleton-wrapper>

            <hr />
            <form class="needs-validation" @submit.prevent="formDatas">
              <div class="col-md-4 col-sm-6 mt-3">
                <div class="row align-items-center">
                  <div class="col-md-9 col-sm-9 col-9">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="personId"> เลือกผู้เดินทะเบียน:</label>
                      <!-- disabled="disabled"  -->
                      <multiselect
                        id="personId"
                        v-model="form.personId"
                        :options="rowPerson"
                        style="ts-15"
                        placeholder="ค้นหาเลขที่ผู้เดินทะเบียน"
                        label="personCode"
                        :show-labels="false"
                        track-by="personCode"
                        open-direction="bottom"
                        :close-on-select="true"
                        :internal-search="true"
                        :custom-label="customLabelPer"
                        @search-change="getPerson($event)"
                        :class="{
                          'is-invalid': submitform && $v.form.personId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="submitform && $v.form.personId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.personId.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-3">
                    <div class="mt-2 position-relative">
                      <b-button
                        class="btn"
                        variant="info"
                        v-b-modal.modalPerson
                      >
                        <i class="uil-file-search-alt"></i
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-4 mt-4">
                <div class="col text-end ms-1">
                  <span
                    ><code>*** กรุณาระบุรับเอกสารให้ครบก่อนยืนยัน</code></span
                  ><br />
                  <button class="btn btn-danger ms-1" type="submit">
                    ยืนยันการส่งเอกสาร
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <b-modal
      ref="modalPerson"
      id="modalPerson"
      title="รายการผู้เดินทะเบียน"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center"></div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-sm-4 col-md-3">
              <div class="mb-3 position-relative">
                <label class="d-inline align-items-center"> สาขา:</label>
                <multiselect
                  v-model="branchIdSearch"
                  :options="localData"
                  label="nameTh"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="col-sm-4 col-md-3">
              <label class="d-inline align-items-center m-2 text-end">
                ชื่อพนักงาน:
                <b-form-input
                  v-model="filterPer.name"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control"
                  @keyup.enter="handleSearch"
                ></b-form-input>
              </label>
            </div>
            <div class="col-sm-4 col-md-3">
              <label class="d-inline align-items-center m-2 text-end">
                นามสกุลพนักงาน:
                <b-form-input
                  v-model="filterPer.familyName"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control"
                  @keyup.enter="handleSearch"
                ></b-form-input>
              </label>
            </div>

            <div class="col-sm-12 col-md-3 text-end">
              <btnComponent @click="handleSearchPer" changeStyle="search">
              </btnComponent
              >&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <!-- <div class="row float-end"></div> -->
          </div>
        </div>
        <b-skeleton-wrapper :loading="loadingPer">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPagePer"
                        size="sm"
                        :options="pageOptionsPer"
                        @input="handlePageChangePer"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowPerson"
              :fields="fieldsPerson"
              responsive="true"
              :per-page="perPagePer"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filterPer"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <!-- End edit table -->
              <template #cell(index)="rowPerson" v-if="this.currentPagePer > 1">
                {{
                  rowPerson.index +
                  1 +
                  (currentPagePer * perPagePer - perPagePer)
                }}
              </template>
              <template #cell(index)="rowPerson" v-else>
                {{ rowPerson.index + 1 }}
              </template>
            </b-table>
          </div>

          <div class="row">
            <span
              v-if="this.totalRecordPer === 0"
              class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecordPer > 0">
              <div class="col">
                หน้า {{ currentPagePer }} จาก {{ this.totalPagePer }} ทั้งหมด
                {{ totalRecordPer }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPagePer"
                      :total-rows="totalRecordPer"
                      :per-page="perPagePer"
                      @change="handleChangePagePer"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalImg"
      id="modalImg"
      title="ภาพประกอบ"
      hide-footer
      size="l"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <img
                :src="this.imagePath"
                class="preview-img"
                @click="singleImage = true"
              />
              <vue-easy-lightbox
                :visible="singleImage"
                :imgs="this.imagePath"
                @hide="singleImage = false"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent.vue";
import documentComponent from "@/components/documentComponent";

// import ChangeDate from "@/components/changeDateComponent";

// import tableData from "@/components/tablecomponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
    documentComponent,
    // ChangeDate,
  },
  page: {
    title: appConfig.sendDocReg,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      apiShow: "api/reg-send-msg/show",
      apiImg: "api/reg-send-msg/full-size-image",
      apiCheck: "api/reg-send-msg/received-doc",
      isReceived: "ส่งเอกสาร",
      receivedBy: "ผู้ส่ง",
      overlayFlag: false,
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      loading: undefined,
      singleImage: false,
      imagePath: "",
      loadingPer: false,
      message: "โปรดตรวจสอบข้อมูล",
      title: "สร้างการส่งเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "ส่งเอกสารให้ผู้เดินทะเบียน",
          href: "/send-docReg",
        },
        {
          text: "สร้างการส่งเอกสาร",
          active: true,
        },
      ],
      rowData: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      filter: {
        startDate: "",
        endDate: "",
        branchId: "",
        licensePlate: "",
        regCode: "",
        rcvdCode: "",
        docTypeId: "",
      },
      filterPer: {
        name: "",
        familyName: "",
      },
      fields: [
        {
          key: "index",
          label: "เลือก",
        },
        {
          key: "regCode",
          sortable: true,
          label: "เลขที่ใบแจ้งจำหน่าย",
        },
        {
          key: "rcvdCode",
          sortable: true,
          label: "เลขที่รับเอกสาร",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "docTypeNameTh",
          sortable: true,
          label: "ประเภทเอกสาร",
        },
        {
          key: "rcvdDate",
          sortable: true,
          label: "วันที่รับเอกสาร",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPerson: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "personCode",
          sortable: true,
          label: "เลขที่พนักงาน",
        },
        {
          key: "name",
          sortable: true,
          label: "ชื่อพนักงาน",
        },
        {
          key: "familyName",
          sortable: true,
          label: "นามสกุลพนักงาน",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "email",
          sortable: true,
          label: "อีเมล",
        },
      ],
      regJobId: this.$route.params.regJobId,

      document: [],
      rowPerson: [],
      rowDocType: [],
      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      totalRecordRe: "",
      rowTest: [],
      totalRows: 1,
      totalRecordPer: "",
      totalPagePer: "",
      perPagePer: 10,
      currentPagePer: 1,
      pageOptionsPer: [5, 10, 25, 50, 100],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: false,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],

      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsData: [],
      optionTypeDoc: [],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],
      form: {
        docId: [],
        branchId: "",
        sendDate: "",
        personId: "",
        note: "",
        regJobCode: "",
        regCode: "",
        customerFullNameTh: "",
        vehicleId: "",
        vin: "",
        modelNameTh: "",
        paymentId: "",
        isSale: "",
      },

      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      personId: {
        required,
      },
      // cause: {
      //   required,
      // },
      // customerDecision: {
      //   required,
      // },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.branchIdSearch = { nameTh: item.nameTh, branchId: item.branchId };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getDataShow();
    this.getPerson();
    // this.getDocType();
    this.getNow();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.form.sendDate = date;
    },
    customLabelPer({ name, familyName, personCode }) {
      return `${name != null ? name : ""}  ${
        familyName != null ? familyName : " "
      }-[${personCode != null ? personCode : ""}  ]`;
    },
    onRowSelected(data) {
      //(data);
      this.selected = data[0];

      var personId = {
        name: this.selected.name,
        familyName: this.selected.familyName,
        personCode: this.selected.personCode,
        personId: this.selected.personId,
      };
      this.form.personId = personId;
      // this.form.branchId = branchName;
      this.$refs["modalPerson"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRo();
    },
    getPerson: function (event) {
      // this.overlayFlag = true;
      this.loadingPer = true; //skeleton true
      useNetw
        .get("api/reg-send-msg/dealer-person", {
          params: {
            page: this.currentPagePer,
            perPage: this.perPagePer, //เฉพาะค้นหา สูงสุด 100
            name: event,
            // familyName: this.filterPer.familyName,
          },
        })
        .then((response) => {
          //(response);
          this.rowPerson = response.data.data;
          const per_Page  = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordPer = response.data.total;
          this.to = response.data.to;
          this.totalPagePer = Math.ceil(this.totalRecordPer / per_Page);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loadingPer = false; //skeleton false
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get(this.apiShow, {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.form.sendDate = response.data.data.regJobDate;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };

          this.form.note = response.data.data.note;
          this.form.status = response.data.data.status;
          this.form.doc = response.data.data.docCode;
          this.form.isSale = response.data.data.isSale;
          this.form.regJobCode = response.data.data.regJobCode;

          this.form.customerFullNameTh = `${
            response.data.data.customerNameTh
          } ${
            response.data.data.customerFamilyNameTh != null
              ? response.data.data.customerFamilyNameTh
              : ""
          }`;
          this.form.vehicleId = response.data.data.licensePlate;

          this.form.vin = response.data.data.vin;
          this.form.modelNameTh = response.data.data.modelNameTh;
          this.form.paymentId = response.data.data.paymentCode;
          this.form.regCode = response.data.data.regCode;

          this.document = response.data.data.document;
          // this.getDocCus();
          // this.getDocSend();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataloadImage: function (data) {
      this.fileId = data.fileId;
      useNetw
        .get(this.apiImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getRecDoc: function () {
      this.loading = true;
      useNetw
        .get("api/reg-send-msg/dealer-reg-rcvd-branch", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.branchIdSearch != null
                ? this.branchIdSearch.branchId
                : this.branchId,
            startDate: this.filter.startDate,
            rcvdCode: this.filter.rcvdCode,
            endDate: this.filter.endDate,
            regCode: this.filter.regCode,
            licensePlate: this.filter.licensePlate,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
          },
        })
        .then((response) => {
          //(response);
          this.rowsData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDocType: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-send-msg/dealer-document-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowDocType = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    putCheck(docId) {
      useNetw
        .put(this.apiCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    putSubmit() {
      useNetw
        .put("api/reg-send-msg/submit", {
          regJobId: atob(this.regJobId),
          personId: this.form.personId.personId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({ name: "sendDocReg" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    formDatas() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.alertSubmit();
      }
    },
    postSubmit() {
      useNetw
        .post("api/reg-send-msg/store", {
          sendDate: this.form.sendDate,
          personId: this.form.personId.personId,
          note: this.form.note,

          docId: this.form.docId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "sendDocReg" });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getRecDoc();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRecDoc();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRecDoc();
    },
    handleSearchPer() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getPerson();
    },
    handleChangePagePer(page) {
      this.currentPagePer = page;
      this.getPerson();
    },
    handlePageChangePer(active) {
      this.perPagePer = active;
      this.currentPagePer = 1;
      this.getPerson();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
